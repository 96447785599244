import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./case-studies.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgDriver from "../images/shuttleid-tablet-with-driver.jpg"
import imgCaseStudyCambridgeBuses from "../images/cambridge-buses-cover-photo-small.jpg"
import imgCaseStudyApplegates from "../images/applegates-coaches-cover-photo-small.jpg"
import imgCaseStudyKeane from "../images/keane-travel-case-study-small.jpg"
import imgCaseStudyPlumpton from "../images/plumpton-college-case-study-small.jpg"
import imgCaseStudyFlagfinders from "../images/flagfinders-case-study-small.jpg"
import imgCaseStudyBullocks from "../images/bullocks-case-study-small.jpg"
import imgCaseStudyYorkCollege from "../images/york-college-case-study-small.jpg"

import Nav from "../components/nav"
import Contact from "../components/contact"
import Hero from "../components/hero-standard"
import Footer from "../components/footer";

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell'
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Case studies | Simple bus pass and ticketing system for home-to-school transport" description="Success stories of transport operators across the UK using ShuttleID to simplify managing their home-to-school services." />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <Hero h1="Case Studies" h2="Real success stories from transport providers" />

              <div className='packages'>
                <div style={{ color: '#333' }}>
                  <div>
                    <div className='case-studies-parent'>



                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college/'><img src={imgCaseStudyYorkCollege} alt="Bullocks using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college/'>ShuttleID credited with <span style={{ color: 'rgb(35, 95, 206)' }}>15% growth in student transport revenue</span> for York College</Link></h3>
                          <p>With a new paper-free system, York College’s student transport service is now back on track.</p>
                          <Link to='/case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-bullocks-months-to-minutes/'><img src={imgCaseStudyBullocks} alt="Bullocks using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-bullocks-months-to-minutes/'>How Bullocks accomplished <span style={{ color: 'rgb(35, 95, 206)' }}>months of work in minutes</span> with ShuttleID</Link></h3>
                          <p>A fourth-generation coach company transformed its school services admin, sparking mass ticket renewals and saving 250+ manpower hours.</p>
                          <Link to='/case-study-bullocks-months-to-minutes/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service/'><img src={imgCaseStudyFlagfinders} alt="Flagfinders using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service/'>How Flagfinders regained <span style={{ color: 'rgb(35, 95, 206)' }}>control of its admin</span> and <span style={{ color: 'rgb(35, 95, 206)' }}>modernised</span> its operation with ShuttleID</Link></h3>
                          <p>This successful family-run coach company revolutionised its admin and mastered its payments and passenger numbers, saving two days' work a week.</p>
                          <Link to='/case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-plumpton-college-save-full-time-admin/'><img src={imgCaseStudyPlumpton} alt="Plumpton College using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-plumpton-college-save-full-time-admin/'>How Plumpton College's travel transformation <span style={{ color: 'rgb(35, 95, 206)' }}>saved a full-time salary</span> with ShuttleID</Link></h3>
                          <p>How one college used ShuttleID to take control of their transport, resulting in reduction of admin and protection of ticket revenue for full-time and part-time students.</p>
                          <Link to='/case-study-plumpton-college-save-full-time-admin/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/'><img src={imgCaseStudyKeane} alt="Keane Travel using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/'>How Keane Travel <span style={{ color: 'rgb(35, 95, 206)' }}>halved their admin</span> and <span style={{ color: 'rgb(35, 95, 206)' }}>increased revenue</span> after introducing ShuttleID</Link></h3>
                          <p>The journey of an Essex based home-to-school operator and how their investment has returned a 50% revenue increase and a 90% reduction in failed payments.</p>
                          <Link to='/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="500" className='case-studies__text'>
                          <p><Link to='/case-study/'><img src={imgDriver} alt="Driver using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study/'>How Tetley's Coaches stopped <span style={{ color: 'rgb(35, 95, 206)' }}>£10,000 of ticket fraud</span> and saved <span style={{ color: 'rgb(35, 95, 206)' }}>240 hours of admin</span></Link></h3>
                          <p>Find out how an established Yorkshire coach company used ShuttleID to transform their school services, saving weeks of admin and ending ticket fraud.</p>
                          <Link to='/case-study/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/'><img src={imgCaseStudyApplegates} alt="Applegates Coaches using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/'>How Applegates <span style={{ color: 'rgb(35, 95, 206)' }}>guaranteed income and safety</span> by going cashless during COVID-19</Link></h3>
                          <p>Read how Applegates Coaches secured the viability and safety of their home-to-school services by going completely cashless in face of a pandemic.</p>
                          <Link to='/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="800" className='case-studies__text'>
                          <p><Link to='/case-study-cambridge-buses-help-local-authority-save-time-money/'><img src={imgCaseStudyCambridgeBuses} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/case-study-cambridge-buses-help-local-authority-save-time-money/'>How Cambridge Buses saved their local authority <span style={{ color: 'rgb(35, 95, 206)' }}>£70,000</span> on transport costs</Link></h3>
                          <p>Read how one forward thinking operator uses ShuttleID to save Cambridgeshire County Council time and money on home-to-school transport.</p>
                          <Link to='/case-study-cambridge-buses-help-local-authority-save-time-money/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
